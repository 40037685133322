import { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import {
    onMounted, reactive, Ref, ref
} from 'vue';
import { isCNServer } from '@/util/location';
import { MainUserData, UserInfo } from '@/views/pm/old-community/resident/module/set/all.type';
import residentApi from '@/api/pm/resident';
import { user } from '@/methods/rule';
import { byteLength } from 'byte-length';

const callTypeOption = [
    {
        label: WordList.ProperAllTextSmartPlusIndoor,
        value: '0'
    }, {
        label: WordList.ProperAllTextPhoneIndoor,
        value: '1'
    }, {
        label: WordList.ProperAllTextSmartPlusIndoorBackup,
        value: '2'
    }, {
        label: WordList.ProperAllTextIndoorSmartPlusBackup,
        value: '3'
    }, {
        label: WordList.ProperAllTextIndoorPhoneBackup,
        value: '4'
    }, {
        label: WordList.ProperAllTextIndoorSmartPlusPhone,
        value: '5'
    }
];

const sipCallOption = [
    {
        label: WordList.ProperAllTextIPCall,
        value: '1'
    },
    {
        label: WordList.ProperAllTextSIPCall,
        value: '0'
    }
];

const checkApartmentName: RuleMethod = (rule, value, callback) => {
    if (byteLength(value as string) > 63) {
        return callback(
            new Error(
                WordList.RuleLengthExceeds63.format(
                    WordList.RDeviceSearchLabelRoomNumber,
                    WordList.RDeviceSearchLabelRoomNumber
                )
            )
        );
    }
    return callback();
};

const initData = (
    propsId: string
) => {
    const formData = ref<MainUserData>({
        Build: '',
        Room: '',
        RoomNumber: '',
        Email: '',
        FirstName: '',
        LastName: '',
        MobileNumber: '',
        Phone: '',
        Phone2: '',
        Phone3: '',
        PhoneCode: isCNServer() ? '86' : '',
        PhoneState: '0',
        EnableIpDirect: '1',
        KeyType: '1',
        Key: '',
        TempKeyPermission: true,
        IsMulti: 0
    });

    const formEle: Ref<any> = ref();

    const breadHeaders: {label: string;path?: string}[] = [
        {
            label: WordList.RDeviceResidents,
            path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResident}`
        }
    ];

    const rules: {
        [key in string]: any[]
    } = reactive({
        RoomNumber: [{
            validator: checkApartmentName,
            trigger: 'blur'
        }],
        Build: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.DeviceDetailDetailUnitName), trigger: 'change' }],
        Room: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.RDeviceSearchLabelRoomName), trigger: 'change' }],
        FirstName: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPropertyManageFirstName), trigger: 'blur' }],
        LastName: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPropertyManageLastName), trigger: 'blur' }],
        Email: [{ validator: user.checkEmail, trigger: 'blur' }],
        MobileNumber: []
    });

    const isDisabledReset = ref(true);
    if (propsId === '') {
        breadHeaders.push({
            label: WordList.ProperAllTextNew
        });
    } else {
        breadHeaders.push({
            label: WordList.TabelConfigInHtmlModify
        });
        onMounted(() => {
            residentApi.getUserInfoOldPm({ ID: propsId }, (res: UserInfo) => {
                formData.value = {
                    ...res,
                    TempKeyPermission: res.TempKeyPermission === '1',
                    Email: res.Email || '',
                    MobileNumber: res.MobileNumber || '',
                    Build: res.UnitID,
                    Room: res.RoomID,
                    PhoneState: res.CallType,
                    IsMulti: res.IsMulti || 0
                };
                isDisabledReset.value = res.Email === '' || res.Email === null;

                if (formData.value.Email) {
                    rules.Email = [
                        { required: true, message: WordList.RuleEmailEmpty, trigger: 'blur' },
                        { validator: user.checkEmail, trigger: 'blur' }
                    ];
                }

                if (formData.value.MobileNumber) {
                    rules.MobileNumber = [
                        { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextMobileNumber), trigger: 'change' }
                    ];
                }
            });
        });
    }

    return {
        formData,
        breadHeaders,
        initData,
        rules,
        formEle,
        isDisabledReset
    };
};
export default initData;
export {
    callTypeOption,
    sipCallOption
};
