
import {
    computed, defineComponent, watch, ref
} from 'vue';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import initData, { callTypeOption, sipCallOption } from '@/views/pm/old-community/resident/module/set/init-data';
import initBuildAptChoose, { Option } from '@/methods/building-apt-choose';
import { isCNServer } from '@/util/location';
import { community, phoneCode as phoneCodeOption } from '@/data';
import initSubmit from '@/views/pm/old-community/resident/module/set/init-submit';
import residentApi from '@/api/pm/resident';
import { getPhoneCodeOps } from '@/util/phone-code';

export default defineComponent({
    components: {
        PropertyBreadCrumb
    },
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const propsId = props.id;

        const {
            breadHeaders,
            formData,
            isDisabledReset,
            rules,
            formEle
        } = initData(propsId);

        // 获取build room
        const {
            buildOptions, aptOptions, setRoomOption
        } = initBuildAptChoose((propsId === '' ? 'empty' : 'all'),
            'aptAndName');

        const copyAptOption = ref<Option[]>([]);
        watch(() => formData.value.Build, (value) => {
            if (value === '') {
                copyAptOption.value = [];
            } else {
                setRoomOption(String(value));
                formData.value.Room = propsId === '' ? '' : formData.value.Room;
                copyAptOption.value = aptOptions.value;
            }
        });

        watch(() => formData.value.Room, (value: string) => {
            aptOptions.value.forEach((apt) => {
                if (apt.key === value) {
                    // eslint-disable-next-line prefer-destructuring
                    formData.value.RoomNumber = apt.value.split(' ')[1];
                }
            });
        });

        watch(() => aptOptions.value, (val) => {
            copyAptOption.value = val;
            aptOptions.value.forEach((apt) => {
                if (apt.key === formData.value.Room) {
                    // eslint-disable-next-line prefer-destructuring
                    formData.value.RoomNumber = apt.value.split(' ')[1];
                }
            });
        });

        const {
            submit
        } = initSubmit(formEle, propsId, formData);

        const isEnableLandline = computed(() => community.IsEnableLandline.value);

        const limitMobileNumberType = (val: string) => {
            const reg = /[^\d]]*/g;
            formData.value.MobileNumber = val.replace(reg, '');
        };

        const reset = () => {
            residentApi.resetUserPassword({
                ID: propsId
            }, () => false);
        };

        return {
            breadHeaders,
            formData,
            buildOptions,
            aptOptions,
            setRoomOption,
            rules,
            isDisabledReset,
            isCNServer: isCNServer(),
            phoneCodeOption,
            callTypeOption,
            isEnableLandline,
            limitMobileNumberType,
            sipCallOption,
            formEle,
            submit,
            copyAptOption,
            reset,
            getPhoneCodeOps
        };
    }
});
