import router, { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import residentApi from '@/api/pm/resident';
import { MainUserData, MainUserSubmitData } from '@/views/pm/old-community/resident/module/set/all.type';
import { Ref } from 'vue';

const initSubmit = (
    formEle: any,
    propsId: string,
    formData: Ref<MainUserData>
) => {
    // 路由跳转到resident
    const routeGoToResident = () => {
        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResident}`);
    };

    const submit = () => {
        formEle.value.validate((valid: boolean) => {
            if (valid) {
                const params: MainUserSubmitData = {
                    ...formData.value,
                    TempKeyPermission: formData.value.TempKeyPermission ? '1' : '0',
                    ID: propsId
                };
                if (propsId === '') {
                    residentApi.addForOldPm(params, routeGoToResident);
                } else {
                    residentApi.editForOldPm(params, routeGoToResident);
                }
            }
            return true;
        });
    };

    return {
        submit
    };
};

export default initSubmit;